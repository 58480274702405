import Alert from "bootstrap/js/dist/alert";
import Collapse from "bootstrap/js/dist/collapse";
import Dropdown from "bootstrap/js/dist/dropdown";
import Modal from "bootstrap/js/dist/modal";
import Offcanvas from "bootstrap/js/dist/offcanvas";
import Tooltip from "bootstrap/js/dist/tooltip";

$("a[data-method]").on("click", function (e) {

  if (this.dataset.confirm) {
    return; // will be handled by confirm handler
  }

  e.preventDefault();

  if (!("csrftoken" in window)) {
    console.error("Define window.csrftoken before using [data-method] links.");
    return;
  }

  const el = $(this);

  const method = el.data("method");
  const url = el.attr("href") || "";

  const form = document.createElement("form");
  form.method = method;
  form.action = url;

  const csrf = document.createElement("input") as HTMLInputElement;
  csrf.type = "hidden";
  csrf.name = "csrfmiddlewaretoken";
  csrf.value = window.csrftoken as string;

  $(form).append(csrf).appendTo("body").trigger("submit");
});

$("[data-dismiss='dropdown']").on("click", function () {
  const el = $(this).parents(".dropdown").find("button[data-bs-toggle='dropdown']");
  const dropdown = Dropdown.getInstance(el[0])!;
  dropdown.hide();
})

$("tr[data-href]").on("click", function (event) {
  if ($(event.target).is("a, button")) {
    return;
  }
  const href = $(this).data("href");
  window.location = href;
});

const collapseElementList = document.querySelectorAll('[data-bs-toggle="collapse"]');
[...collapseElementList].forEach(el => new Collapse(el))

const alertList = document.querySelectorAll('.alert');
[...alertList].forEach(el => new Alert(el))

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
[...tooltipTriggerList].forEach(el => new Tooltip(el))

const offcanvasElementList = document.querySelectorAll('.offcanvas');
[...offcanvasElementList].forEach(el => new Offcanvas(el))

document.querySelectorAll(".js-clipboard").forEach(el => {
  el.addEventListener("click", function (e) {
    e.preventDefault();

    const target = e.currentTarget! as HTMLElement;

    copyToClipboard(target.previousElementSibling! as HTMLInputElement);

    const title = target.dataset.copied || "Copied!";

    const tooltip = Tooltip.getInstance(target);
    if (tooltip) {
      tooltip.setContent({ '.tooltip-inner': title });
    }
  });
});

function copyToClipboard(elem: HTMLInputElement) {
  const target = elem;

  // select the content
  const currentFocus = document.activeElement as HTMLElement;

  target.focus();
  target.setSelectionRange(0, target.value.length);

  // copy the selection
  let succeed;
  try {
    succeed = document.execCommand("copy");
  } catch (e) {
    console.warn(e);
    succeed = false;
  }

  // Restore original focus
  if (currentFocus && typeof currentFocus.focus === "function") {
    currentFocus.focus();
  }
  return succeed;
}

const intentTypes = ["primary", "secondary", "success", "danger", "warning", "info"];

function intentClasses(prefix: string) {
  return intentTypes.map(s => `${prefix}-${s}`);
}

$("[data-confirm]").on("click", function (e) {
  e.preventDefault();

  const el = $(this);
  const message = el.data("confirm");

  const modalEl = document.getElementById('confirmModal')!
  modalEl.querySelector(".modal-body .prompt")!.innerHTML = message;

  const form = modalEl?.querySelector("form") as HTMLFormElement
  form.method = el.data("method") || "get";
  form.action = el.attr("href") || "";

  const intent = el.data("intent") || "danger";
  const icon = el.data("icon") || "exclamation-lg";

  const submitButton = form.querySelector<HTMLButtonElement>("button[type=submit]")!;

  submitButton.classList.remove(...intentClasses("btn"));
  submitButton.classList.add("btn-" + intent);
  submitButton.innerText = `${el.data("label") || submitButton.dataset.defaultLabel}`;
  form.onsubmit = function () {
    submitButton.disabled = true;
  }

  const iconContainer = form.querySelector<HTMLDivElement>(".icon-container")!;

  iconContainer.classList.remove(...intentClasses("bg-soft"), ...intentClasses("text"));
  iconContainer.classList.add("bg-soft-" + intent, "text-" + intent);
  iconContainer.children[0].className = "bi bi-" + icon;

  const modal = Modal.getOrCreateInstance(modalEl)
  modal.show();
});

const workspacePicker = document.getElementById("workspacePicker");
workspacePicker?.addEventListener("change", async function (ev) {
  const target = (ev.currentTarget! as HTMLSelectElement)
  const { value } = target;

  const resp = await fetch(
    `/workspaces/${value}/set_default`,
    {
      method: "POST",
      headers: { "X-CSRFToken": window.csrftoken as string },
    }
  )
  if (!resp.ok) {
    console.error("Failed to set default workspace", resp);
  }
})

function isValidIdSelector(id: string): boolean {
  return /^#[a-z][a-z0-9_-]*$/i.test(id);
}

function enableOffCanvasHashOpening() {
  const hash = window.location.hash;
  if (!hash || !isValidIdSelector(hash)) {
    return;
  }

  const target = document.querySelector(hash);
  if (target?.classList.contains("offcanvas")) {
    const offcanvas = Offcanvas.getOrCreateInstance(target)
    offcanvas.show();
  }
}

enableOffCanvasHashOpening();
