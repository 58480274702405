import Offcanvas from "bootstrap/js/dist/offcanvas";

function isValidIdSelector(id: string): boolean {
    return /^#[a-z][a-z0-9_-]*$/i.test(id);
}

function enableOffCanvasHashOpening() {
    const hash = window.location.hash;
    if (!hash || !isValidIdSelector(hash)) {
        return;
    }

    const target = document.querySelector(hash);
    if (target?.classList.contains("offcanvas")) {
        const offcanvas = Offcanvas.getOrCreateInstance(target)
        offcanvas.show();
    }
}

enableOffCanvasHashOpening();
