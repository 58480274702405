import htmx from "htmx.org";
import TomSelect from "tom-select";
import type {
    RecursivePartial,
    TomInput,
    TomOption,
    TomSettings
} from "tom-select/dist/esm/types/index.js";

// templates
function item(data: TomOption, escape: (str: string) => string) {
    const label = data.label || data.text;
    return `<div><span class="h5 mb-0">${escape(label)}</span></div>`;
}

function option(data: TomOption, escape: (str: string) => string) {
    if (data.label && data.secondary) {
        return `<div>
            <span class="h5">${escape(data.label)}</span>
            <span class="d-block text-truncate">${escape(data.secondary)}</span>
        </div>`;
    } else {
        const label = data.label || data.text;
        return `<div><span class="h5">${escape(label)}</span></div>`;
    }
}

function no_results(this: TomSelect, _: TomOption, escape: (str: string) => string) {
    const label = this.input.dataset.noResults;
    return label ? `<div class="no-results">${escape(label)}</div>` : "";
}
// end templates

const settings: RecursivePartial<TomSettings> = {
    maxOptions: 100,
    searchField: ["label", "secondary"],
    render: { item, option, no_results }
}

htmx.onLoad(function (elt: Node) {
    const root = elt as HTMLElement;

    root.querySelectorAll<TomInput>(".tomselect")
        .forEach(el => new TomSelect(el, settings));
});
