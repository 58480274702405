import Tooltip from "bootstrap/js/dist/tooltip";

function copyToClipboard(elem: HTMLInputElement) {
    const target = elem;

    // select the content
    const currentFocus = document.activeElement as HTMLElement;

    target.focus();
    target.setSelectionRange(0, target.value.length);

    // copy the selection
    let succeed;
    try {
        succeed = document.execCommand("copy");
    } catch (e) {
        console.warn(e);
        succeed = false;
    }

    // Restore original focus
    if (currentFocus && typeof currentFocus.focus === "function") {
        currentFocus.focus();
    }
    return succeed;
}

function setupCopyableInput(el: HTMLInputElement) {
    el.addEventListener("click", function (e) {
        e.preventDefault();

        const target = e.currentTarget! as HTMLElement;

        copyToClipboard(target.previousElementSibling! as HTMLInputElement);

        const title = target.dataset.copied || "Copied!";

        const tooltip = Tooltip.getInstance(target);
        if (tooltip) {
            tooltip.setContent({ '.tooltip-inner': title });
        }
    });
}

document.querySelectorAll<HTMLInputElement>(".js-clipboard")
    .forEach(setupCopyableInput);
