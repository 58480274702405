import htmx from "htmx.org"
(globalThis as any).htmx = htmx;

// TODO: remove chart.js from signer pages, use a separate bundle
// TODO: take advantage of tree-shaking to reduce chart.js bundle size
import Chart from "chart.js/auto"
(globalThis as any).Chart = Chart;

import Alert from "bootstrap/js/dist/alert";
import Collapse from "bootstrap/js/dist/collapse";
import Dropdown from "bootstrap/js/dist/dropdown";
import Modal from "bootstrap/js/dist/modal";
import Offcanvas from "bootstrap/js/dist/offcanvas";
import Tooltip from "bootstrap/js/dist/tooltip";

(globalThis as any).Dropdown = Dropdown;

import "../lib/copyable-input";
import "../lib/offcanvas";
import "../lib/tomselect";

// TODO: move it to a single listener at document level for all [data-method] links
// See [data-confirm] handler below
document.querySelectorAll("a[data-method]").forEach(el => {
  el.addEventListener("click", function (this: HTMLAnchorElement, e: Event) {
    if (this.dataset.confirm) {
      return; // will be handled by confirm handler
    }

    e.preventDefault();

    if (!("csrftoken" in window)) {
      console.error("Define window.csrftoken before using [data-method] links.");
      return;
    }

    const method = this.dataset.method || "get";
    const url = this.href || "";

    const form = document.createElement("form");
    form.method = method;
    form.action = url;

    const csrf = document.createElement("input") as HTMLInputElement;
    csrf.type = "hidden";
    csrf.name = "csrfmiddlewaretoken";
    csrf.value = window.csrftoken as string;

    form.appendChild(csrf);
    document.body.appendChild(form);

    form.submit();
  });
});

document.querySelectorAll("[data-dismiss='dropdown']").forEach(el => {
  el.addEventListener("click", function (this: HTMLElement, e: Event) {
    e.preventDefault();
    const dropdownEl = this.closest(".dropdown")!
      .querySelector("[data-bs-toggle='dropdown']")!;

    const dropdown = Dropdown.getInstance(dropdownEl)!;
    dropdown.hide();
  });
});

document.addEventListener("click", function (e: Event) {
  const target = e.target as HTMLElement;
  if (target.matches("a, button")) {
    return;
  }
  const row = target.closest<HTMLElement>("tr[data-href]");
  if (row) {
    const href = row.dataset.href!;
    window.location.href = href;
  }
});

const collapseElementList = document.querySelectorAll('[data-bs-toggle="collapse"]');
[...collapseElementList].forEach(el => new Collapse(el))

const alertList = document.querySelectorAll('.alert');
[...alertList].forEach(el => new Alert(el))

const offcanvasElementList = document.querySelectorAll('.offcanvas');
[...offcanvasElementList].forEach(el => new Offcanvas(el))

htmx.onLoad(function (elt: Node) {
  const root = elt as HTMLElement;

  const tooltipTriggerList = root.querySelectorAll('[data-bs-toggle="tooltip"]');
  [...tooltipTriggerList].forEach(el => Tooltip.getOrCreateInstance(el))
});

const intentTypes = ["primary", "secondary", "success", "danger", "warning", "info"];

function intentClasses(prefix: string) {
  return intentTypes.map(s => `${prefix}-${s}`);
}

document.addEventListener("click", function (e) {
  const target = e.target as HTMLElement;

  const el = target.closest<HTMLAnchorElement>("a[data-confirm]");
  if (!el) {
    return
  }

  e.preventDefault();

  const message = el.dataset.confirm || "";

  const modalEl = document.getElementById("confirmModal")!
  modalEl.querySelector(".modal-body .prompt")!.innerHTML = message;

  const form = modalEl?.querySelector("form") as HTMLFormElement
  form.method = el.dataset.method || "get";
  form.action = el.href || "";

  const intent = el.dataset.intent || "danger";
  const icon = el.dataset.icon || "exclamation-lg";

  const submitButton = form.querySelector<HTMLButtonElement>("button[type=submit]")!;

  submitButton.classList.remove(...intentClasses("btn"));
  submitButton.classList.add("btn-" + intent);
  submitButton.innerText = `${el.dataset.label || submitButton.dataset.defaultLabel}`;
  form.onsubmit = function () {
    submitButton.disabled = true;
  }

  const iconContainer = form.querySelector<HTMLDivElement>(".icon-container")!;

  iconContainer.classList.remove(...intentClasses("bg-soft"), ...intentClasses("text"));
  iconContainer.classList.add("bg-soft-" + intent, "text-" + intent);
  iconContainer.children[0].className = "bi bi-" + icon;

  const modal = Modal.getOrCreateInstance(modalEl)
  modal.show();
});
